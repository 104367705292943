import React from "react"
import Layout from "../components/layout-static"
import Seo from "../components/seo"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Self Insurance Defense - Bartlett & Grippe",
    description:
      "Need help with complex insurance claims? Bartlett & Grippe LLC's experienced attorneys handle property, coverage, and personal injury claims for insureds and insurers nationwide.",
    heroH1: "Insurance Defense",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <p className="mb-8">
              At Bartlett & Grippe LLC, we represent insureds and insurance
              companies throughout the United States.. We have extensive
              experience in the insurance industry and our Connecticut
              litigation attorneys are experienced and capable of handling
              complex property, coverage and personal injury claims. We are
              listed with A.M. Best’s Directory of Recommended Insurance
              Attorneys.
            </p>
            <p className="mb-8">
              The insurance defense attorneys at Bartlett & Grippe LLC are
              committed to providing effective and efficient professional
              representation. The firm manages cases carefully to minimize
              expense while protecting the interests of its clients. We
              coordinate our defense strategy with our clients, seeking cost
              effective yet aggressive solutions in all insurance defense
              matters. Our attorneys will take the time to investigate the facts
              and make recommendations regarding early settlement opportunities.
              When settlement options are not feasible our attorneys have the
              trial experience needed to protect our clients.
            </p>
            <p className="mb-8">
              We have successfully defended and represented some of the largest
              insurance companies in all types of insurance claims and
              subrogation matters, including:
            </p>
            <ul className="list-disc list-outside ml-6 mb-8 space-y-2">
              <li>Wrongful death claims</li>
              <li>Construction defect claims</li>
              <li>Catastrophic injuries</li>
              <li>Motor vehicle accident inquiries</li>
              <li>Truck accidents</li>
              <li>Special Investigations Unit defense</li>
              <li>Property damage claims</li>
              <li>Premises liability claims</li>
              <li>Product liability claims</li>
              <li>Dram shop liability claims</li>
              <li>Dog bite injuries</li>
              <li>Bad faith claims</li>
              <li>Declaratory judgments</li>
              <li>Coverage opinions</li>
            </ul>
            <p className="mb-8">
              Our attorneys routinely advise clients on coverage issues
              associated with claims arising out of automobile, homeowners,
              commercial general liability and excess insurance policies. We
              regularly provide clients with coverage opinions concerning issues
              such as the duty to defend, reservation of rights, primary/excess
              issues, uninsured/underinsured coverage, applicability of policy
              exclusions and endorsements, and duties of the insured under the
              policy. We have also advised and represented insurance companies
              against claims of bad faith and breach of contract.
            </p>
            <p className="mb-8">
              If your insurance firm is seeking quality, experienced and
              effective legal counsel, contact a member of our insurance defense
              litigation team.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
